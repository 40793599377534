import React, { Component, useMemo, useState } from "react";
import classNames from "classnames";
import DemoButton from "components/demo_button";
import Button from "components/button";

import * as styles from "./index.module.scss";
import VerticalStrip from "images/icons/edo-vertical-strip.svg";

interface PageBannerProps {
  title?: string;
  description?: string;
  descriptionNode?: React.ReactNode;
  headerImagePath?: string;
  headerBackgroundPath?: string;
  requestDemoButton?: boolean;
  exploreButton?: boolean;
  otherButtonText?: string;
  otherButtonLink?: string;
  verticalStrip?: boolean;
  tintBackgroundImage?: boolean;
  center?: boolean;
  tintDescription?: boolean;
}

const PageBanner: React.FC<PageBannerProps> = (props)  => {
  const {
    title,
    description,
    descriptionNode,
    headerImagePath,
    headerBackgroundPath,
    requestDemoButton = false,
    exploreButton = false,
    otherButtonText,
    otherButtonLink,
    verticalStrip = false,
    tintBackgroundImage = true,
    center,
    tintDescription=false
  } = props;

  let centerDiv : String = center? "margin-auto-left margin-auto-right" : "";
  let centerStyle : String = center? styles.pageHeader : "";
  let centerContainer : String = center? "uk-child-width-expand@s uk-grid edo-container uk-container uk-container-center" : "uk-child-width-expand@s uk-grid edo-container";
  return (
    <div className={classNames(centerDiv, centerStyle)}>
    <div className={classNames(centerContainer, styles.pageHeader, { [styles.backgroundImage]: !!headerBackgroundPath, [styles.tint]: !!headerBackgroundPath && tintBackgroundImage })}
      style={{
        backgroundImage: `${headerBackgroundPath && `url(${headerBackgroundPath})`}`
      }}
      data-uk-grid>
      <div className={classNames("uk-flex uk-flex-between uk-flex-row", styles.headerContent)}>
        <div className={classNames("uk-flex uk-flex-column", styles.titleContainer)}>
          <div className={styles.title}>
            { title }
          </div>
          { description && (
            <div className={classNames(styles.description, {[styles.tintDescription]: tintDescription})}>
              {description}
            </div>
          ) }
          {
            descriptionNode
          }
          {
            requestDemoButton && <div className="margin-top-32"><DemoButton /></div>
          }
          {
            exploreButton && <div className="margin-top-32"><Button to={"/contact-us"}>REQUEST A DEMO</Button></div>
          }
          {
            otherButtonText &&
            otherButtonLink &&
              <div className="margin-top-32">
                  <Button to={otherButtonLink}>{otherButtonText.toUpperCase()}</Button>
              </div>
          }
        </div>

        { headerImagePath && (
          <div className={styles.headerImage}>
            <img src={headerImagePath} />
          </div>
        ) }
        { verticalStrip && (
          <div className={styles.verticalStrip}>
            <img src={VerticalStrip} />
          </div>
        ) }
      </div>
    </div>
    </div>
  )
};

export default PageBanner;
