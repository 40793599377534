import React, { useState, useMemo } from "react";
import * as styles from "./index.module.scss";
import Layout from "components/layout";
import classNames from "classnames";
import {graphql} from "gatsby";
import Paginator from "components/paginator";
import { addIndex, map } from "ramda";
import ResourceCard from "components/resource_card";
import PageBanner from "components/page_banner";
import {getCloudFrontImageUrl} from "utils/helpers";


const PAGE_LIMIT = 12;

const ResourcesPage = ({ data  }) => {
  const [currentPage, setCurrentPage] = useState(1);
  // const wpPosts = data.allWpPost.nodes;
  // // Wordpress is supposed to display posts in reverse chronological order but it doesn't always, we can enforce it here
  // const posts = useMemo(() => {
  //   return wpPosts.sort((a,b) => (Date.parse(b?.date) || 0) - (Date.parse(a?.date) || 0));
  // }, [wpPosts])

  const dateFormatOptions: Intl.DateTimeFormatOptions = { weekday: undefined, year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <Layout pageTitle={"Resources"}>
      {/* page header */}
      <PageBanner
        title={"Know What Works"}
        description={"EDO is at the leading edge of Convergent TV measurement. With our unique data, we help brands, agencies, networks, and data partners understand what triggers consumers to take action. Whether it's our latest weekly intelligence, sports & live event coverage, industry reports, case studies, or just the latest news  — EDO helps make sure you Know What Works."}
        headerBackgroundPath={getCloudFrontImageUrl("advertisers/resources-main.jpg")}
        verticalStrip
      />

      {/*<div className="edo-container uk-margin-top">*/}
      {/*  <ul className={classNames("uk-subnav uk-margin", styles.resourceNavigation)}>*/}
      {/*    <li className="uk-active "><a href="/resources">All</a></li>*/}
      {/*    <li><a href="/resources/news">News</a></li>*/}
      {/*    /!* case studies - custom page *!/*/}
      {/*    /!*<li><a href="/resources/case-studies">Case Studies</a></li>*!/*/}
      {/*    /!* <li><a href="/resources/weekly-intelligence">Weekly Intelligence</a></li> *!/*/}
      {/*    <li><a href="/resources/industry-reports">Industry Reports</a></li>*/}
      {/*    <li><a href="/resources/sports-and-live-events">Sports & Live Events</a></li>*/}
      {/*    <li><a href="/resources/upfronts">Upfronts</a></li>*/}
      {/*    <li><a href="/resources/thought-leadership">Thought Leadership</a></li>*/}
      {/*    <li><a href="/resources/tv-and-film">TV & Film</a></li>*/}
      {/*    <li><a href="/resources/research-and-insights">Research & Insights</a></li>*/}
      {/*  </ul>*/}
      {/*</div>*/}

      {/*<div className="edo-container uk-margin-top">*/}
      {/*  <div className="uk-grid uk-flex" data-uk-grid>*/}

      {/*    {*/}
      {/*      addIndex(map)((post, i) => (*/}
      {/*        <div>*/}
      {/*          <ResourceCard*/}
      {/*            id={i}*/}
      {/*            post={post}*/}
      {/*          />*/}
      {/*        </div>*/}

      {/*      ), posts.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT))*/}
      {/*    }*/}

      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className={classNames(styles.paginatorContainer, "uk-margin-top")}>*/}
      {/*  <Paginator*/}
      {/*    extraClassNames={styles.paginator}*/}
      {/*    totalCount={posts.length}*/}
      {/*    limitPerPage={PAGE_LIMIT}*/}
      {/*    onPageNumberChange={(newPageNumber) => {setCurrentPage(newPageNumber)}}*/}
      {/*    currentPage={currentPage}*/}
      {/*  />*/}
      {/*</div>*/}

    </Layout>

  )
}

// export const query = graphql`
//   query resourcePosts($in: [Int] = [694, 697, 703, 706, 709, 712, 715, 718, 721, 771]) {
//     allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {in: $in}}}}}) {
//       nodes {
//         id
//         uri
//         slug
//         title
//         content
//         date
//         author {
//           node {
//             id
//             name
//             description
//           }
//         }
//         featuredImage {
//           node {
//             id
//             sourceUrl
//           }
//         }
//         categories {
//           nodes {
//             id
//             name
//             nodeType
//             databaseId
//             slug
//           }
//         }
//         tags {
//           nodes {
//             id
//             name
//           }
//         }
//       }
//     }
//   }
// `

export default ResourcesPage;
