import React from "react";
import classNames from "classnames";
import * as styles from "./index.module.scss";
import Button from "components/button";

declare global {
  interface Window {
    lintrk?: any;
    gtag?: any;
    liQ?: any;
  }
}

const DemoButton: React.FC<{className?: string}> = ({className})  => {
  return (
    <div onClick={() => {
      
      if (window.lintrk) {
        window.lintrk('track', { conversion_id: 9377316 });
      }
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          'allow_custom_scripts': true,
          'send_to': 'DC-12429278/demo/reque0+unique'
        });
      }
      
      }}>
      <Button to={"/contact-us"} id="top-ads-button" className={classNames(className, styles.demoButton)}>
        Request a Demo
      </Button>
    </div>
  )
}

export default DemoButton;
